import React from "react"
import styled from "styled-components"
import media from "../../../../utils/media"
//@ts-ignore
import BaseSvg from "../../../../images/Before_footer_banner_circle.svg"

const PatternParent = styled.div`
  margin-top: -84px;
  position: absolute;
  left: 0;

  ${media.down("md")`
  margin-top: -90px;
  `}
`

const BasePattern = styled.img`
  z-index: 2;
`

const LeftSidePattern: React.FC = () => {
  return (
    <PatternParent>
      <BasePattern src={BaseSvg} alt={"base image pattern"} />
    </PatternParent>
  )
}

export default LeftSidePattern
