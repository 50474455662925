import React from "react"
import Layout from "../components/layout"
import {
  MainSection,
  GetStartedSteps,
  UseCases,
  ContactUs,
} from "../components/sections/lander"
import mixpanel from "mixpanel-browser"

const Canvas = () => {
  mixpanel.init("a4dc3b341ed72e8db72174ead73f47a9", { debug: false })

  //@see https://www.gatsbyjs.com/docs/debugging-html-builds/#fixing-third-party-modules
  const hasDocument = typeof document !== "undefined"

  mixpanel.track_links("#joinBetaBtn", "clicked join beta", {
    referrer: hasDocument ? document.referrer : null,
  })

  mixpanel.track_links("#joinBetaNavBtn", "clicked join beta", {
    referrer: hasDocument ? document.referrer : null,
  })

  mixpanel.track_links("#knowMoreBtn", "clicked know more", {
    referrer: hasDocument ? document.referrer : null,
  })

  return (
    <Layout>
      <MainSection />
      <GetStartedSteps />
      <UseCases />
      <ContactUs />
    </Layout>
  )
}

export default Canvas
