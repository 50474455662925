import React from "react"
import styled from "styled-components"
//@ts-ignore
import BaseSvg from "../../../../images/use_cases_left_blob.svg"
//@ts-ignore
import TopSvg from "../../../../images/use_cases_left_triangle.svg"

const PatternParent = styled.div`
  margin-top: -54px;
  position: absolute;
  left: 0;
`

const BasePattern = styled.img`
  z-index: 2;
`
const TopPattern = styled.img`
  z-index: 3;
  margin-left: 0px;
  left: -88px;
  position: relative;
  top: 12px;
`

const LeftSidePattern: React.FC = () => {
  return (
    <PatternParent>
      <BasePattern src={BaseSvg} alt={"base image pattern"} />
      <TopPattern src={TopSvg} alt={"top svg pattern"} />
    </PatternParent>
  )
}

export default LeftSidePattern
