import React from "react"
import styled from "styled-components"
import Content from "../../../content"
import LeftSidePattern from "./leftSidePattern"
import RightSidePattern from "./rightSidePattern"
import Button from "../../../button"
import Link from "../../../link"
import media from "../../../../utils/media"

const Section = styled.div`
  margin-top: 156px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 436px;
  width: 100%;
`

const ContactUsContent = styled(Content)`
  background-color: #2e458d;
`

const CTA = styled.span`
  padding-bottom: 32px;
  font-family: Cabin, sans-serif;
  color: #fff;
  font-size: 35px;
  line-height: 43px;
  ${media.down("md")`
    text-align: center;
  `}
`
const WhiteButton = styled(Button)`
  background-color: white;
`

const ContactUs: React.FC = () => {
  return (
    <>
      <ContactUsContent>
        <LeftSidePattern />
        <Section>
          <CTA>{"Wanna know more? Get in touch!"}</CTA>
          <Link
            target={"_blank"}
            to={
              "https://outlook.office365.com/owa/calendar/UnifeidAI@getunifiedai.com/bookings/"
            }
          >
            <WhiteButton>{"Get in touch"}</WhiteButton>
          </Link>
        </Section>
        <RightSidePattern />
      </ContactUsContent>
    </>
  )
}

export default ContactUs
