import React from "react"
import styled from "styled-components"
import media from "../../../../utils/media"

//@ts-ignore
import BaseSvg from "../../../../images/use_cases_right_blob.svg"
//@ts-ignore
import TopSvg from "../../../../images/use_cases_right_circle.svg"

const PatternParent = styled.div`
  margin-top: -120px;
  position: absolute;
  right: 0;

  ${media.down("md")`
  margin-top: -210px;
  `}
`

const BasePattern = styled.img`
  z-index: 2;
`
const TopPattern = styled.img`
  z-index: 3;
  margin-right: 0px;
  right: -88px;
  position: relative;
  top: 36px;
`

const RightSidePattern: React.FC = () => {
  return (
    <PatternParent>
      <TopPattern src={TopSvg} alt={"top svg pattern"} />
      <BasePattern src={BaseSvg} alt={"base image pattern"} />
    </PatternParent>
  )
}

export default RightSidePattern
