import React from "react"
import styled from "styled-components"
import GetStartedDiagram from "./diagram"
import Content from "../../../content"
import media from "../../../../utils/media"

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 32px;
  padding-top: 32px;
  padding-bottom: 64px;
  height: 620px;

  ${media.down("md")`
  flex-direction: column;
  height: auto;
  
  .title-text {
    text-align: center;
  }
  `}
`

const ContentWithoutBackground = styled(Content)`
  background-color: white;
  z-index: 20;
`

const GetStartedSteps: React.FC = () => {
  return (
    <ContentWithoutBackground>
      <Section>
        <h2 className={"title-text"}>{"Get started in 3 simple steps..."}</h2>
        <GetStartedDiagram />
      </Section>
    </ContentWithoutBackground>
  )
}

export default GetStartedSteps
