import React from "react"
import styled from "styled-components"
//@ts-ignore
import BaseSvg from "../../../../images/before_footer_banner_slashes.svg"

const PatternParent = styled.div`
  margin-top: -211px;
  position: absolute;
  right: 0;
`

const BasePattern = styled.img`
  z-index: 2;
`

const RightSidePattern: React.FC = () => {
  return (
    <PatternParent>
      <BasePattern src={BaseSvg} alt={"base image pattern"} />
    </PatternParent>
  )
}

export default RightSidePattern
