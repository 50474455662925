import React, { useState } from "react"
import styled from "styled-components"
import media from "../../../../utils/media"
import { StaticImage } from "gatsby-plugin-image"
// @ts-ignore
import TwitterSvg from "../../../../images/Twitter Logo.svg"
// @ts-ignore
import GoogleDocsSvg from "../../../../images/google-docs.svg"
// @ts-ignore
import GMailSvg from "../../../../images/gmail.svg"
//@ts-ignore
import ArrowSvg from "../../../../images/blue-thick-down-arrow.svg"
//@ts-ignore
import SolutionSvg from "../../../../images/brain_future_technology.svg"
//@ts-ignore
import ResultsSvg from "../../../../images/process_flow_result.svg"

const imagesBasePath = "../../../../images/"

const DiagramContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 64px;

  height: 380px;
  width: 90vw;
  max-width: 1440px;

  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 24px;
  background-color: #2d458a;

  ${media.down("xl")`
  column-gap: 12px;
  `}

  ${media.down("md")`
  flex-direction: column;
  height: 760px;
  row-gap: 36px;
  
  .arrow-diagram {
    visibility: hidden;
    height: 0px;
    width: 0px;
  }
  `}
`

const StepIconBox = styled.div`
  display: flex;
  height: 162.65px;
  width: 168px;
  flex-direction: column;
  justify-content: center;

  ${media.down("xl")`
  height: 90px;
  width: 80px;

  `}
`

const StepIconRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StepIcon = styled.div`
  display: flex;
  margin-bottom: 16px;
  background-color: transparent;
  width: 50%;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex-grow: 1;
  img {
    width: 75%;
  }
`

const StepSingleIcon = styled(StepIcon)`
  img {
    width: 100%;
  }
`

interface IStepIconBubble {
  iconLoaded: boolean
}
const StepIconBubble = styled(StepIcon)<IStepIconBubble>`
  .gatsby-image-wrapper > div:first-of-type {
    visibility: ${({ iconLoaded }) => (iconLoaded ? "hidden" : "visible")};
  }

  img {
    background-color: white;
    border-radius: 10%;
    left: 15%;
  }
`

const StepSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 32px;
`

const SubtitleText = styled.span`
  max-width: 280px;
  color: #fff;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
`

const DataSourceStep: React.FC = () => {
  const [imageLoaded, setImageLoaded] = useState({ bubble: false })

  return (
    <StepSection>
      <StepIconBox>
        <StepIconRow>
          <StepIcon>
            <img src={TwitterSvg} alt="image" />
          </StepIcon>
          <StepIconBubble iconLoaded={imageLoaded.bubble}>
            <StaticImage
              src={`${imagesBasePath}bubble_logo.png`}
              alt="image"
              onLoad={() => {
                setImageLoaded({
                  ...imageLoaded,
                  bubble: true,
                })
              }}
            />
          </StepIconBubble>
        </StepIconRow>
        <StepIconRow>
          <StepIcon>
            <img src={GoogleDocsSvg} alt="image" />
          </StepIcon>
          <StepIcon>
            <img src={GMailSvg} alt="image" />
          </StepIcon>
        </StepIconRow>
      </StepIconBox>
      <SubtitleText>{"Select data source"}</SubtitleText>
    </StepSection>
  )
}

const ArrowIcon = styled(StepIcon)`
  img {
    transform: rotate(-90deg);
    width: 75%;
  }
`

const ArrowDiagram: React.FC = () => {
  return (
    <StepSection className={"arrow-diagram"}>
      <StepIconBox>
        <StepIconRow>
          <ArrowIcon>
            <img src={ArrowSvg} alt="arrow icon" />
          </ArrowIcon>
        </StepIconRow>
      </StepIconBox>
    </StepSection>
  )
}

const PickSolutionDiagram: React.FC = () => {
  return (
    <StepSection>
      <StepIconBox>
        <StepIconRow>
          <StepSingleIcon>
            <img src={SolutionSvg} alt="arrow icon" />
          </StepSingleIcon>
        </StepIconRow>
      </StepIconBox>
      <SubtitleText>{"Pick a solution"}</SubtitleText>
    </StepSection>
  )
}

const ResultsDiagram: React.FC = () => {
  return (
    <StepSection>
      <StepIconBox>
        <StepIconRow>
          <StepSingleIcon>
            <img src={ResultsSvg} alt="arrow icon" />
          </StepSingleIcon>
        </StepIconRow>
      </StepIconBox>
      <SubtitleText>{"Get results ⚡"}</SubtitleText>
    </StepSection>
  )
}

const GetStartedDiagram: React.FC = () => {
  return (
    <DiagramContainer>
      <DataSourceStep />
      <ArrowDiagram />
      <PickSolutionDiagram />
      <ArrowDiagram />
      <ResultsDiagram />
    </DiagramContainer>
  )
}

export default GetStartedDiagram
