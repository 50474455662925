import React from "react"
import styled from "styled-components"
import mediaQuery from "../../../../utils/media"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../../../button"
import Content from "../../../content"

const imagesBasePath = "../../../../images/"

const HeroText = styled.h1`
  width: 505px;
  left: 72px;
  top: 239px;
  ${mediaQuery.down("md")`
    width: 100%;
  `}
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 64px;

  ${mediaQuery.down("md")`
  flex-direction: column;
  row-gap: 12px;
  margin-top: 32px;
  `}
`
interface IFiller {
  width: string
}
const Filler = styled.div<IFiller>`
  width: ${props => props.width};
  ${mediaQuery.down("xl")`
    visibility: hidden;
    width: 0px;
  `}
`

const LeftSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 12px;

  ${mediaQuery.down("xl")`
  width: 70%;
  `}

  ${mediaQuery.down("md")`
  width: 100%;
  row-gap: 24px;
  align-items: center;
  `}
`
const RightSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  ${mediaQuery.down("md")`
  width: 100vw;
  margin-top: 36px;
  justify-content: flex-start;
  `}

  ${mediaQuery.down("md")`
  margin-top: 36px;
  `}
`

const CustomButton = styled(Button)`
  background-color: #2d458a;
  color: white;
`

const Subtitle = styled.span`
  font-family: Cabin;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #6b6a6a;
`

const QuarterCircleContainer = styled.div`
  margin-left: -200px;
  height: 150.45px;
  overflow: hidden;

  ${mediaQuery.down("xl")`
  margin-left: -240px;
  height: 100.45px;
  `}

  ${mediaQuery.down("md")`
  margin-left: -270px;
  height: 80.45px;
  `}
`

const QuarterCircleImage = styled.span`
  width: 356.45px;
  height: 356.45px;
  background: linear-gradient(180deg, #69b8ff 0%, #311996 92.58%);
  border-radius: 50%;
  display: inherit;
`

const MainLanderSection: React.FC = () => {
  return (
    <>
      <Content>
        <Section>
          <Filler width={"10%"} />
          <LeftSection>
            <HeroText>{`Harness the power of AI with
  the ease of no-code.`}</HeroText>
            <Subtitle>{`Use our powerful AI-centric workflows and integrations to speed up business processes by up to 70%. No coding or technical experience required.`}</Subtitle>
            <CustomButton>{"Get access"}</CustomButton>
          </LeftSection>

          <RightSection>
            <StaticImage
              src={imagesBasePath + "No_code_apps_hero.svg"}
              alt="No Code Apps Hero image"
              style={{
                verticalAlign: "inherit",
                zIndex: 2,
              }}
            />
            <StaticImage
              src={imagesBasePath + "Dots_hero_right.svg"}
              alt="Dots Hero image"
              style={{
                verticalAlign: "inherit",
                position: "relative",
                left: "-76px",
                top: "-32px",
                right: "0%",
                bottom: "0%",
                clear: "none",
                transform: "translate3d(0px, 0px, -138px)",
                transformStyle: "preserve-3d",
                zIndex: 1,
              }}
            />
          </RightSection>
        </Section>
      </Content>
      <QuarterCircleContainer>
        <QuarterCircleImage />
      </QuarterCircleContainer>
    </>
  )
}

export default MainLanderSection
