import React from "react"
import styled from "styled-components"
import Content from "../../../content"
import LeftSidePattern from "./leftSidePattern"
import RightSidePattern from "./rightSidePattern"
import media from "../../../../utils/media"

//@ts-ignore
import DocumentSvg from "../../../../images/document_flaticon.svg"
//@ts-ignore
import SocialIntelligenceSvg from "../../../../images/social_media_bullhorn.svg"
//@ts-ignore
import ContentAwarenessSvg from "../../../../images/content_flaticon.svg"
//@ts-ignore
import SocialMediaSvg from "../../../../images/social_media_flaticon_real.svg"
//@ts-ignore
import FactorySVG from "../../../../images/factoryIcon.svg"

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: auto;

  margin-top: 64px;

  h2 {
    color: #6b6a6a;
  }

  ${media.down("lg")`
  margin-top: 64px;
  margin-bottom: 190px;
  `}
`

const ContentWithSecondaryBackground = styled(Content)`
  background-color: #e4f2ff;
  z-index: 0;
`

const InfoParent = styled.div`
  margin-top: 64px;
  display: grid;
  margin-bottom: 0px;
  grid-auto-columns: 1fr;
  grid-column-gap: 64px;
  grid-row-gap: 32px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;

  ${media.down("md")`
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto;
  `}
`

interface IInfoCard {
  primary?: boolean
}
const InfoCard = styled.div<IInfoCard>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 12px;

  width: auto;
  max-width: 411px;
  height: 320px;

  padding-left: 32px;
  border-radius: 24px;
  background-color: ${({ primary }) => (primary ? "#2d458a" : "#fff")};
  box-shadow: 1px 1px 4px 2px rgba(53, 63, 179, 0.25);
  transform: translate(0px, 0px);

  z-index: 4;

  p,
  span,
  div {
    color: ${({ primary }) => (primary ? "white" : "#6b6a6a")};
  }

  ${media.between("md", "lg")`
  width: 313px;
  `}

  ${media.down("md")`
  width: 75vw;
  height: auto;
  padding-top: 32px;
  padding-bottom: 32px;
  `}
`

const CardImage = styled.img`
  width: 80.5px;
  height: 85px;
  border: 2px solid transparent;
  border-radius: 42px;
  background-color: #fff;
`
const CardTitle = styled.span`
  font-size: 24px;
  font-weight: 400;
  line-height: 28.3px;
  letter-spacing: normal;
  text-indent: 0px;
  white-space: normal;
`

const CardDescription = styled.p`
  font-size: 18px;
  line-height: 24.96px;
  font-weight: 400;
  text-align: left;
  margin-right: 60px;
`

const UseCases: React.FC = () => {
  return (
    <>
      <ContentWithSecondaryBackground>
        <LeftSidePattern />
        <Section id={"useCases"}>
          <h2>Use Cases</h2>

          <InfoParent>
            <InfoCard primary={true}>
              <CardImage src={FactorySVG} />
              <CardTitle>Visual Quality Control</CardTitle>
              <CardDescription>
                Save time and money by automating visual quality control
                processes with ease.
              </CardDescription>
            </InfoCard>

            <InfoCard>
              <CardImage src={DocumentSvg} />
              <CardTitle>Digital Invoice Extraction</CardTitle>
              <CardDescription>
                Extract data from digital invoices and store it in Excel, CRM or
                any place you want it.
              </CardDescription>
            </InfoCard>

            <InfoCard>
              <CardImage src={SocialIntelligenceSvg} />
              <CardTitle>Social Intelligence</CardTitle>
              <CardDescription>
                Keep track of customer responses on social media and understand
                customer requests on the fly.
              </CardDescription>
            </InfoCard>

            {/* <InfoCard>
              <CardImage src={ContentAwarenessSvg} />
              <CardTitle>Content Awareness</CardTitle>
              <CardDescription>
                UnifiedAI filters all your media so your content moderation team
                focuses only on the ambiguous ones.
              </CardDescription>
            </InfoCard> */}

            <InfoCard>
              <CardImage src={SocialMediaSvg} />
              <CardTitle>Consumer Satisfaction</CardTitle>
              <CardDescription>
                Get deep insights into consumer satisfaction in your
                marketplace. Easily understand what customers really think about
                your products.
              </CardDescription>
            </InfoCard>
          </InfoParent>
        </Section>
        <RightSidePattern />
      </ContentWithSecondaryBackground>
    </>
  )
}

export default UseCases
